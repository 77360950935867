// ========================================
// Detect browser and add class to </body>
// ========================================

// Detect Firefox
const isFirefox = navigator.userAgent.includes("Firefox");

// Add class "is-firefox" to </body>
if (isFirefox) {
    document.body.classList.add("is-firefox");
}




// =======================================================================================
// Detect touch device (do not remove!!!)
// Info: https://developer.mozilla.org/en-US/docs/Web/HTTP/Browser_detection_using_the_user_agent#mobile_device_detection
// =======================================================================================

let isMobile = false;

if (navigator.maxTouchPoints > 0) {
    isMobile = true;
} else if (navigator.msMaxTouchPoints > 0) {
    isMobile = true;
} else {
    const mQ = window.matchMedia("(pointer:coarse)");
    if (mQ.media === "(pointer:coarse)") {
        isMobile = mQ.matches;
    } else if ("orientation" in window) {
        isMobile = true; // deprecated, but good fallback
    } else {
        // Only as a last resort, fall back to user agent sniffing
        isMobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Nokia|Opera Mini|Tablet|Mobile/i.test(navigator.userAgent);
    }
}

// Add class "is-mobile" to </body>
if (isMobile) {
    document.body.classList.add("is-mobile");
}





// =======================================================================================
// Page transitions
// =======================================================================================

if (document.body.classList.contains("tt-transition")) {

    // Wait until the whole page is loaded.
    window.addEventListener("load", function () {
        setTimeout(function () {
            hideLoad(); // call out animations.
        }, 0);
    });

    // Transitions In (when "ptr-overlay" slides in).
    function revealLoad() {
        const tlTransitIn = gsap.timeline({
            defaults: { duration: 0.5, ease: "power3.out" }
        });
    
        if (document.getElementById("page-transition")) {
            tlTransitIn.set("#page-transition", { autoAlpha: 1 });
            tlTransitIn.fromTo(".ptr-overlay", 
                { scaleY: 0, transformOrigin: "center bottom" },
                { scaleY: 1, duration: 0.7 }
            );
            tlTransitIn.fromTo(".ptr-preloader", 
                { autoAlpha: 0, y: 20 },
                { autoAlpha: 1, y: 0 },
                "-=0.5"
            );
        }
    
        tlTransitIn.fromTo("#content-wrap", 
            { y: 0, autoAlpha: 1 },
            { y: -60, autoAlpha: 0, duration: 0.6 },
            "-=0.6"
        );
        tlTransitIn.fromTo("#tt-header", 
            { y: 0, autoAlpha: 1 },
            { y: -20, autoAlpha: 0, duration: 0.6 },
            "-=0.6"
        );
    }

    // Transitions Out (when "ptr-overlay" slides out)
    function hideLoad() {
        const tlTransitOut = gsap.timeline({
            defaults: { ease: "power3.out" }
        });
    
        if (document.getElementById("page-transition")) {
            tlTransitOut.to(".ptr-preloader", { 
                duration: 0.8, 
                autoAlpha: 0,
                y: -40
            });
            tlTransitOut.to(".ptr-overlay", { 
                duration: 0.8, 
                scaleY: 0, 
                transformOrigin: "center top" 
            }, "-=0.6");
        }
    
        // tt-Header appear
        tlTransitOut.from("#tt-header", { 
            duration: 1, 
            y: -20, 
            autoAlpha: 0, 
            clearProps: "all" 
        }, "-=0.4");
    
        // tt-Footer appear
        tlTransitOut.from("#tt-footer", { 
            duration: 1, 
            y: 20, 
            autoAlpha: 0, 
            clearProps: "all" 
        }, "-=0.8");
    
        // Page header image appear
        const phImage = document.querySelector(".ph-image");
        if (phImage) {
            if (document.getElementById("page-header").classList.contains("ph-bg-image")) {
                tlTransitOut.from(".ph-image img, .ph-video", { 
                    duration: 1.5, 
                    scale: 1.1, 
                    autoAlpha: 0, 
                    ease: "power3.out", 
                    clearProps: "all" 
                }, "-=0.8");
            } else {
                tlTransitOut.from(".ph-image", { 
                    duration: 1.5, 
                    y: 80, 
                    autoAlpha: 0, 
                    ease: "power3.out", 
                    clearProps: "all" 
                }, "-=0.8");
            }
        }
    
        // Page header elements appear
        const phAppear = document.querySelectorAll(".ph-appear");
        if (phAppear.length) {
            tlTransitOut.from(phAppear, { 
                duration: 1, 
                y: 40, 
                autoAlpha: 0, 
                stagger: 0.2, 
                ease: "power3.out", 
                clearProps: "all" 
            }, "-=1");
        }
    
        // Page content elements appear
        const pageContent = document.getElementById("page-content");
        if (pageContent) {
            tlTransitOut.from(pageContent, { 
                duration: 1, 
                autoAlpha: 0, 
                y: 20, 
                ease: "power2.out", 
                clearProps: "all" 
            }, "-=0.8");
        }
    
        if (document.getElementById("page-transition")) {
            tlTransitOut.set("#page-transition", { 
                duration: 1, 
                autoAlpha: 0, 
                ease: "power2.inOut" 
            });
        }
    }

    // Force page a reload when browser "Back" button click.
    window.onpageshow = function (event) {
        if (event.persisted) {
            window.location.reload();
        }
    }

    // On link click
    document.querySelectorAll("a")
        .forEach(link => {
            if (!link.matches('[target="_blank"], [href^="#"], [href^="mailto"], [href^="tel"], .lg-trigger, .tt-btn-disabled, .no-transition')) {
                link.addEventListener('click', function (e) {
                    e.preventDefault();
                    setTimeout(function (url) {
                        window.location = url;
                    }, 500, this.href);
                    revealLoad(); // call in animations.
                });
            }
        });
}






//for view-transition API
// if (document.body.classList.contains("tt-transition")) {
//     window.addEventListener("load", function () {
//         setTimeout(function () {
//             hideLoad(); // call out animations.
//         }, 0);
//     });
//     function hideLoad() {
//         const tlTransitOut = gsap.timeline();
//         // Page header elements appear (elements with class "ph-appear")
//         const phAppear = document.querySelectorAll(".ph-appear");
//         if (phAppear.length) {
//             tlTransitOut.from(phAppear, { duration: .5, y: 40, autoAlpha: 0, stagger: 0.3, ease: Expo.easeOut, clearProps: "all" }, .3);
//         }
//     }
// }








// =======================================================================================
// Main menu (classic)
// =======================================================================================


// Main menu hover
document.querySelectorAll(".tt-main-menu-list").forEach(menuList => {
    menuList.addEventListener("mouseenter", function () {
        this.classList.add("tt-mm-hover");
    });
    menuList.addEventListener("mouseleave", function () {
        this.classList.remove("tt-mm-hover");
    });
});


// Mobile menu (for classic menu)
// ===============================

// Open/close mobile menu on toggle button click
document.getElementById("tt-m-menu-toggle-btn-wrap").addEventListener("click", function () {
    document.documentElement.classList.toggle("tt-no-scroll");
    document.body.classList.toggle("tt-m-menu-open");
    document.body.classList.add("tt-m-menu-active");

    if (document.body.classList.contains("tt-m-menu-open")) {

        // Menu step in animations
        document.body.classList.add("tt-m-menu-toggle-no-click"); // Disable toggle button click until the animations last.

        // Menu in animations
        const tl_MenuIn = gsap.timeline({
            onComplete: function () {
                document.body.classList.remove("tt-m-menu-toggle-no-click");
            }
        });

        tl_MenuIn.to(".tt-main-menu", { duration: 0.4, autoAlpha: 1 });
        tl_MenuIn.from(".tt-main-menu-content > ul > li", { duration: 0.4, y: 80, autoAlpha: 0, stagger: 0.05, ease: Power2.easeOut });
        tl_MenuIn.from(".social-buttons ul li", { duration: 0.4, y: 80, autoAlpha: 0, stagger: 0.05, ease: Power2.easeOut }, "-=0.2");

        // On menu link click
        document.querySelectorAll(".tt-main-menu a, .tt-logo a")
            .forEach(link => {
                if (!link.matches('[target="_blank"], [href^="#"], [href^="mailto"], [href^="tel"]')) {
                    link.addEventListener('click', function () {
                        gsap.set("#content-wrap", { autoAlpha: 0 });
                        gsap.to(".tt-main-menu-content > ul > li", { duration: 0.4, y: -80, autoAlpha: 0, stagger: 0.05, ease: Power2.easeIn });
                    });
                }
            });

    } else {

        // Menu step out animations
        document.body.classList.add("tt-m-menu-toggle-no-click"); // Disable toggle button click until the animations last.

        // Menu out animations
        const tl_MenuOut = gsap.timeline({
            onComplete: function () {
                document.body.classList.remove("tt-m-menu-toggle-no-click");
            }
        });

        tl_MenuOut.to(".tt-main-menu-content > ul > li", { duration: 0.4, y: -80, autoAlpha: 0, stagger: 0.05, ease: Power2.easeIn });
        tl_MenuOut.to(".tt-main-menu", { duration: 0.4, autoAlpha: 0, clearProps: "all" }, "+=0.2");
        tl_MenuOut.to(".tt-main-menu-content > ul > li", { clearProps: "all" }); // clearProps only
        tl_MenuOut.to(".social-buttons ul li", { duration: 0.4, y: -80, autoAlpha: 0, stagger: 0.05, ease: Power2.easeIn }, "-=0.2");
        tl_MenuOut.to(".social-buttons ul li", { clearProps: "all" }); // clearProps only

        // Close open submenus
        setTimeout(function () {
            document.querySelectorAll(".tt-submenu").forEach(submenu => submenu.style.display = 'none');
            document.querySelectorAll(".tt-submenu-trigger").forEach(trigger => trigger.classList.remove("tt-m-submenu-open"));
            document.body.classList.remove("tt-m-menu-active");
        }, 900);
    }

    return false;
});

// Append element if link href contains #
document.querySelectorAll('.tt-submenu-trigger > a[href^="#"]').forEach(link => {
    const span = document.createElement('span');
    span.classList.add('tt-submenu-trigger-m');
    link.parentElement.appendChild(span);
});

// Open submenu if link href contains #
document.querySelectorAll('.tt-submenu-trigger > a[href^="#"]').forEach(link => {
    link.nextElementSibling.addEventListener("click", function () {
        const parent = this.parentElement;
        if (parent.classList.contains("tt-m-submenu-open")) {
            parent.classList.remove("tt-m-submenu-open");
            parent.nextElementSibling.style.display = 'none';
        } else {
            parent.parentElement.querySelectorAll(".tt-submenu").forEach(submenu => submenu.style.display = 'none');
            parent.parentElement.querySelectorAll(".tt-submenu-trigger").forEach(trigger => trigger.classList.remove("tt-m-submenu-open"));
            parent.classList.toggle("tt-m-submenu-open");
            parent.nextElementSibling.style.display = 'block';
        }
    });
});

// Open submenu on caret click
document.querySelectorAll(".tt-submenu-trigger").forEach(trigger => {
    const span = document.createElement('span');
    span.classList.add('tt-m-caret');
    trigger.appendChild(span);
});

document.querySelectorAll(".tt-m-caret").forEach(caret => {
    caret.addEventListener("click", function () {
        const parent = this.parentElement;
        if (parent.classList.contains("tt-m-submenu-open")) {
            parent.classList.remove("tt-m-submenu-open");
            parent.nextElementSibling.style.display = 'none';
        } else {
            parent.parentElement.querySelectorAll(".tt-submenu").forEach(submenu => submenu.style.display = 'none');
            parent.parentElement.querySelectorAll(".tt-submenu-trigger").forEach(trigger => trigger.classList.remove("tt-m-submenu-open"));
            parent.classList.toggle("tt-m-submenu-open");
            parent.nextElementSibling.style.display = 'block';
        }
    });
});



// ============================================================================
// Isotope
// More info: http://isotope.metafizzy.co
// Note: "imagesloaded" plugin is required! https://imagesloaded.desandro.com/
// ============================================================================

// init Isotope
const container = document.querySelector(".isotope-items-wrap");
if (container) {
    imagesLoaded(container, function () {
        const iso = new Isotope(container, {
            itemSelector: ".isotope-item",
            layoutMode: "packery",
            transitionDuration: "0.7s",
            percentPosition: true
        });

        setTimeout(function () {
            iso.layout(); // Refresh Isotope
            ScrollTrigger.refresh(true); // Refresh ScrollTrigger
        }, 500);
    });
}

// Filter
document.querySelectorAll(".ttgr-cat-list > li > a, .ttgr-cat-classic-list > li > a").forEach(link => {
  link.addEventListener("click", function (e) {
    e.preventDefault();
    const selector = this.getAttribute("data-filter");
    iso.arrange({ filter: selector });

    // Refresh ScrollTrigger
    setTimeout(function () {
      ScrollTrigger.refresh(true);
    }, 500);
  });
});

// Filter item active
const filterItemActive = document.querySelectorAll(".ttgr-cat-list > li > a, .ttgr-cat-classic-list > li > a");
filterItemActive.forEach(link => {
  link.addEventListener("click", function () {
    if (!this.classList.contains("active")) {
      filterItemActive.forEach(item => item.classList.remove("active"));
      this.classList.add("active");
    }
  });
});



	// =====================================================
	// lightGallery 
	// Source: https://github.com/sachinchoolur/lightGallery
	// =====================================================

    document.addEventListener('DOMContentLoaded', function() {
        const lightgalleryElements = document.querySelectorAll('.lightgallery');
        if (lightgalleryElements.length > 0) {
            lightgalleryElements.forEach(function(element) {
                
                lightGallery(element, {
                plugins: [lgThumbnail],
                thumbnail: true,
                speed: 500,
                download: false,
                selector: 'a',
            });
        });
    }
    });



// ================================================================
// Page header
// ================================================================

// If page header exist
// =====================
if (document.getElementById("page-header")) {
    document.body.classList.add("page-header-on");
  
    // If full height enabled
    if (document.getElementById("page-header").classList.contains("ph-full")) {
      document.body.classList.add("ph-full-on");
    }
  
    // If position center enabled
    if (document.getElementById("page-header").classList.contains("ph-center")) {
      document.body.classList.add("ph-center-on");
    }
  
    // If page header image exist
    if (document.querySelector(".ph-image")) {
      document.body.classList.add("ph-image-on");
  
      // If page header image is background image
      if (document.getElementById("page-header").classList.contains("ph-bg-image")) {
        document.body.classList.add("ph-bg-image-on");
      }
    }
  }
  
  // If page header background image is light
  // =========================================
  if (document.querySelector("#page-header.ph-bg-image.ph-bg-image-is-light")) {
    document.querySelectorAll(".ph-bg-image-is-light").forEach(element => {
      element.addEventListener("mouseenter", function () {
        document.body.classList.add("tt-light-bg-hover");
      });
      element.addEventListener("mouseleave", function () {
        document.body.classList.remove("tt-light-bg-hover");
      });
    });
  }
  
  // ================================================================
  // Next project
  // ================================================================
  
  if (document.querySelector(".tt-np-image")) {
    document.body.classList.add("tt-np-image-on");
  }





// ================================================================
// GSAP ScrollTrigger plugin
// More info: https://greensock.com/docs/v3/Plugins/ScrollTrigger/
// ================================================================

    // Page header elements scrolling effects
    // =======================================
    const pageHeader = document.getElementById("page-header");
    if (pageHeader && pageHeader.classList.contains("ph-content-parallax")) {
        let tlPhParallax = gsap.timeline({
        scrollTrigger: {
            trigger: "#page-header",
            start: 'top top',
            end: 'bottom top',
            scrub: true,
            markers: false
        }
        });
    
        // Page header caption elements
        if (document.getElementById("page-header").classList.contains("ph-bg-image")) {
        if (document.querySelector(".ph-caption")) {
            if (document.getElementById("page-header").classList.contains("ph-center")) {
            tlPhParallax.to(".ph-caption", { y: 180, opacity: 0, scale: 0.95, transformOrigin: "center" }, 0);
            } else {
            tlPhParallax.to(".ph-caption", { y: 180, opacity: 0, scale: 0.95, transformOrigin: "left" }, 0);
            }
        }
        if (document.querySelector(".ph-image")) {
            tlPhParallax.to(".ph-image-inner", { yPercent: 30 }, 0);
        }
    
        } else {
    
        if (document.querySelector(".ph-categories")) {
            tlPhParallax.to(".ph-categories", { y: -220 }, 0);
        }
        if (document.querySelector(".ph-caption-subtitle")) {
            tlPhParallax.to(".ph-caption-subtitle", { y: -230 }, 0);
        }
        if (document.querySelector(".ph-caption-title")) {
            tlPhParallax.to(".ph-caption-title", { y: -180 }, 0);
        }
        if (document.querySelector(".ph-caption-description")) {
            tlPhParallax.to(".ph-caption-description", { y: -120 }, 0);
        }
        if (document.querySelector(".ph-meta")) {
            tlPhParallax.to(".ph-meta", { y: -220 }, 0);
        }
        if (document.querySelector(".ph-image")) {
            tlPhParallax.to(".ph-image-inner", { y: -100 }, 0);
        }
    
        // Page header ghost
        const phGhost = document.querySelector(".ph-caption-title-ghost");
        if (phGhost) {
            if (!document.getElementById("page-header").classList.contains("ph-center")) {
            document.getElementById("page-header").appendChild(phGhost);
            if (document.getElementById("page-header").classList.contains("ph-ghost-scroll")) {
                phGhost.querySelector(".ph-appear").innerHTML = '<span class="phgh-text">' + phGhost.querySelector(".ph-appear").innerHTML + '</span>';
                const phghText = phGhost.querySelector(".phgh-text");
                for (let i = 0; i < 3; i++) {
                phGhost.querySelector(".ph-appear").appendChild(phghText.cloneNode(true));
                }
                tlPhParallax.to(phGhost, { y: -60, xPercent: -8 }, 0);
            } else {
                tlPhParallax.to(phGhost, { y: -30 }, 0);
            }
            } else {
            tlPhParallax.to(phGhost, { y: -60 }, 0);
            }
        }
        }
    
        // Page header scroll down circle
        if (document.querySelector(".tt-scroll-down")) {
        gsap.to(".tt-scroll-down", {
            // y: 100,
            autoAlpha: 0,
            ease: "none",
            scrollTrigger: {
            trigger: "#page-header",
            start: "top top",
            end: "30% top",
            scrub: true,
            markers: false
            },
        });
        }
    
        // Page header project share
        if (document.querySelector(".ph-share")) {
        gsap.to(".ph-share-inner", {
            // y: 100,
            autoAlpha: 0,
            ease: "none",
            scrollTrigger: {
            trigger: "#page-header",
            start: "top top",
            end: "30% top",
            scrub: true,
            markers: false
            },
        });
        }
    }
  
        // If page header is visible
        if (document.getElementById("page-header")) {
            ScrollTrigger.create({
            trigger: "#page-header",
            start: "top bottom",
            end: "bottom 60px",
            scrub: true,
            markers: false,
        
            onLeave: () => phVisibleLeaveClass(),
            onEnter: () => phVisibleEnterClass(),
            onLeaveBack: () => phVisibleLeaveClass(),
            onEnterBack: () => phVisibleEnterClass(),
            });
        
            function phVisibleLeaveClass() {
            document.body.classList.remove("tt-ph-visible");
            }
            function phVisibleEnterClass() {
            document.body.classList.add("tt-ph-visible");
            }
        }
        
        // If page header background image is light
        if (document.querySelector("#page-header.ph-bg-image.ph-bg-image-is-light")) {
            ScrollTrigger.create({
            trigger: "#page-header",
            start: "top bottom",
            end: "bottom 30px",
            scrub: true,
            markers: false,
        
            onLeave: () => phLightLeaveClass(),
            onEnter: () => phLightEnterClass(),
            onLeaveBack: () => phLightLeaveClass(),
            onEnterBack: () => phLightEnterClass(),
            });
        
            function phLightLeaveClass() {
            document.body.classList.remove("tt-light-bg-on");
            }
            function phLightEnterClass() {
            document.body.classList.add("tt-light-bg-on");
            }
        }


    // Next project parallax
    // ======================
    if (document.querySelector(".tt-np-image")) {
        gsap.from(".tt-np-image", {
        yPercent: -30,
        opacity: 0,
        ease: "none",
        scrollTrigger: {
            trigger: ".tt-next-project",
            start: "5% bottom",
            end: "bottom bottom",
            scrub: true,
            markers: false,
        },
        });
    
        if (document.querySelector(".tt-np-caption")) {
        gsap.from(".tt-np-caption", {
            yPercent: -70,
            opacity: 0,
            scale: 0.96,
            transformOrigin: "left",
            ease: "none",
            scrollTrigger: {
            trigger: ".tt-next-project",
            start: "15% bottom",
            end: "bottom bottom",
            scrub: true,
            markers: false,
            },
        });
        }
    
    } else {
    
        if (document.querySelector(".tt-np-caption")) {
        gsap.from(".tt-np-caption", {
            yPercent: -10,
            opacity: 0,
            scale: 0.96,
            ease: "none",
            scrollTrigger: {
            trigger: ".tt-next-project",
            start: "40% bottom",
            end: "bottom bottom",
            scrub: true,
            markers: false,
            },
        });
        }
    }

    
    const ttNextProject = document.querySelector(".tt-next-project");
    // If next project is touches the top of the page
    if (ttNextProject) {
        ScrollTrigger.create({
        trigger: ".tt-next-project",
        start: "top 90px",
        end: "bottom top",
        scrub: true,
        markers: false,
    
        onLeave: () => ttNpTopLeaveClass(),
        onEnter: () => ttNpTopEnterClass(),
        onLeaveBack: () => ttNpTopLeaveClass(),
        onEnterBack: () => ttNpTopEnterClass(),
        });
    
        function ttNpTopLeaveClass() {
        document.body.classList.remove("tt-next-project-top");
        }
        function ttNpTopEnterClass() {
        document.body.classList.add("tt-next-project-top");
        }
    }
    
    // // If next project background image is light
    if (ttNextProject && ttNextProject.classList.contains("tt-np-image-is-light")) {
        ScrollTrigger.create({
        trigger: ".tt-next-project",
        start: "top 90px",
        end: "bottom top",
        scrub: true,
        markers: false,
    
        onLeave: () => ttNpLightLeaveClass(),
        onEnter: () => ttNpLightEnterClass(),
        onLeaveBack: () => ttNpLightLeaveClass(),
        onEnterBack: () => ttNpLightEnterClass(),
        });
    
        function ttNpLightLeaveClass() {
        document.body.classList.remove("tt-light-bg-on");
        }
        function ttNpLightEnterClass() {
        document.body.classList.add("tt-light-bg-on");
        }
    
        // Hover
        document.querySelector(".tt-next-project").addEventListener("mouseenter", function () {
        document.body.classList.add("tt-light-bg-hover");
        });
        document.querySelector(".tt-next-project").addEventListener("mouseleave", function () {
        document.body.classList.remove("tt-light-bg-hover");
        });
    }


    // tt-Grid "layout-creative" parallax
    // ===================================
    ScrollTrigger.matchMedia({
        "(min-width: 768px)": function () {
            document.querySelectorAll(".tt-grid.ttgr-layout-creative-1 .tt-grid-item:nth-of-type(6n+2) .ttgr-item-inner, .tt-grid.ttgr-layout-creative-1 .tt-grid-item:nth-of-type(6n+4) .ttgr-item-inner, .tt-grid.ttgr-layout-creative-2 .tt-grid-item:nth-of-type(4n+2) .ttgr-item-inner, .tt-grid.ttgr-layout-creative-2 .tt-grid-item:not(:last-child):nth-of-type(4n+3) .ttgr-item-inner").forEach(function (item) {
                var thisItem = item;

                gsap.to(thisItem, {
                    yPercent: -50,
                    ease: "none",
                    scrollTrigger: {
                        trigger: thisItem,
                        start: "top bottom",
                        end: "bottom top",
                        scrub: true,
                        markers: false,
                        onEnter: () => ttgrItemParalRefresh(),
                    },
                });

                // Refresh start/end positions on enter.
                function ttgrItemParalRefresh() {
                    ScrollTrigger.refresh(true);
                }
            });
        }
    });


    // Skew on scroll (https://codepen.io/GreenSock/pen/eYpGLYL)
    // ===============
    if (!isMobile) { // Not for mobile devices!

        var skewElement = document.querySelectorAll(".skew-on-scroll"); // Skew element class.
        var skewMaxAngle = 7; // Max angle.
        var skewVelocity = 500; // Velocity.
        var skewDuration = 0.5; // Duration.

        var proxy = { skew: 0 };
        var clamp = (value) => Math.max(Math.min(value, skewMaxAngle), -skewMaxAngle);

        if (skewElement.length) {

            function skewer() {
                ScrollTrigger.create({
                    onUpdate: (self) => {
                        var skew = clamp(self.getVelocity() / -skewVelocity);
                        if (Math.abs(skew) > Math.abs(proxy.skew)) {
                            proxy.skew = skew;
                            gsap.to(proxy, { skew: 0, duration: skewDuration, ease: "power1.easeInOut", overwrite: true, onUpdate: () => gsap.set(skewElement, { skewY: proxy.skew }) });
                        }
                    }
                });
            }

            function skewerIfSmoothScroll() {
                var bodyScrollBar = Scrollbar.init(document.getElementById("scroll-container"));

                ScrollTrigger.scrollerProxy("#scroll-container", {
                    scrollTop(value) {
                        if (arguments.length) {
                            bodyScrollBar.scrollTop = value;
                        }
                        return bodyScrollBar.scrollTop;
                    }
                });

                ScrollTrigger.create({
                    scroller: "#scroll-container",
                    onUpdate: (self) => {
                        var skew = clamp(self.getVelocity() / -skewVelocity);
                        if (Math.abs(skew) > Math.abs(proxy.skew)) {
                            proxy.skew = skew;
                            gsap.to(proxy, { skew: 0, duration: skewDuration, ease: "power1.easeInOut", overwrite: true, onUpdate: () => gsap.set(skewElement, { skewY: proxy.skew }) });
                        }
                    }
                });
            }

            skewer();
            gsap.set(skewElement, { transformOrigin: "center center", force3D: true }); // Change transform origin if needed.
        }
    }



    // Scrolling button
    // =================
    if (document.querySelectorAll(".tt-scrolling-btn").length) {
        document.querySelectorAll(".tt-scrolling-btn").forEach(button => {
        const scrBtnSvg = button.querySelector(".scr-btn-spinner");
        gsap.from(scrBtnSvg, {
            rotate: 240,
            ease: "none",
            scrollTrigger: {
            trigger: scrBtnSvg,
            start: "-40% bottom",
            end: "120% top",
            scrub: true,
            markers: false,
            },
        });
        });
    }


    // Appear on scroll
    // =================

    // zoom in
    document.querySelectorAll(".anim-zoomin").forEach(element => {

        // Add wrap <div>.
        const wrap = document.createElement('div');
        wrap.classList.add('anim-zoomin-wrap');
        element.parentNode.insertBefore(wrap, element);
        wrap.appendChild(element);
    
        // Add overflow hidden.
        wrap.style.overflow = "hidden";
    
        let tl_ZoomIn = gsap.timeline({
        scrollTrigger: {
            trigger: wrap,
            start: "top bottom",
            markers: false,
            onEnter: () => animZoomInRefresh(),
        }
        });
        tl_ZoomIn.from(element, { duration: 1.5, autoAlpha: 0, scale: 1.2, ease: Power2.easeOut, clearProps: "all" });
    
        // Refresh start/end positions on enter.
        function animZoomInRefresh() {
        ScrollTrigger.refresh();
        }
    });
    
    // fade in-up
    document.querySelectorAll(".anim-fadeinup").forEach(element => {
        let tl_FadeInUp = gsap.timeline({
        scrollTrigger: {
            trigger: element,
            start: "top bottom",
            markers: false
        }
        });
    
        tl_FadeInUp.from(element, { duration: 2.5, autoAlpha: 0, y: 100, ease: Expo.easeOut, clearProps: "all" }, "+=0.3");
    });
    
    // skew in-up
    document.querySelectorAll(".anim-skewinup").forEach(element => {
        let tl_SkewInUp = gsap.timeline({
        scrollTrigger: {
            trigger: element,
            start: "top bottom",
            markers: false
        }
        });
    
        tl_SkewInUp.from(element, { duration: 2, skewY: 5, transformOrigin: "left top", autoAlpha: 0, y: 100, ease: Expo.easeOut, clearProps: "all" }, "+=0.3");
    });





// ================================================================
// Portfolio interactive
// ================================================================

    document.querySelectorAll(".portfolio-interactive-images, .portfolio-interactive-ghost").forEach(element => {
        document.getElementById("body-inner").appendChild(element);
    });
    
    const portfolioInteractive = document.querySelector(".portfolio-interactive");
    if (portfolioInteractive && portfolioInteractive.classList.contains("pi-inline")) {
        document.body.classList.add("pi-inline-on");
    }
    
    if (portfolioInteractive && portfolioInteractive.classList.contains("pi-center")) {
        document.body.classList.add("pi-center-on");
    }
    
    if (portfolioInteractive && portfolioInteractive.classList.contains("pi-full")) {
        document.body.classList.add("pi-full-on");
    
    }
    
    // Item link hover
    // ================
    document.querySelectorAll(".portfolio-interactive-item").forEach(item => {
        const piItemTitleLink = item.querySelector(".pi-item-title-link");
        piItemTitleLink.addEventListener("mouseenter", function () {
        // hover title
        this.closest(".portfolio-interactive").classList.add("hovered");
        this.parentElement.classList.add("pi-item-hover");
    
        // hover video
        const piItemVideo = document.querySelector(".pi-item-image video");
        if (piItemVideo) {
            piItemVideo.play();
        }
        });
    
        piItemTitleLink.addEventListener("mouseleave", function () {
        // hover title
        this.closest(".portfolio-interactive").classList.remove("hovered");
        this.parentElement.classList.remove("pi-item-hover");
    
        // hover video
        const piItemVideo = document.querySelector(".pi-item-image video");
        if (piItemVideo) {
            piItemVideo.pause();
        }
        });
    });
    
    // If item image is light
    // =======================
    document.querySelectorAll(".pi-item-image").forEach(image => {
        if (image.classList.contains("pi-item-image-is-light")) {
        image.closest(".portfolio-interactive-item").classList.add("pi-item-light-image-on");
        }
    });




// ================================================================
// Portfolio grid
// ================================================================

    // If "pgi-cap-inside enabled
    // ===========================
    const portfolioGrid = document.querySelector("#portfolio-grid");
    if (portfolioGrid && portfolioGrid.classList.contains("pgi-cap-inside")) {

        // Move "pgi-caption" to inside "pgi-image-wrap".
        document.querySelectorAll(".portfolio-grid-item").forEach(item => {
        const caption = item.querySelector(".pgi-caption");
        const imageWrap = item.querySelector(".pgi-image-wrap");
        if (caption && imageWrap) {
            imageWrap.appendChild(caption);
        }
        });
    
        // Remove grid item title anchor tag if exist.
        document.querySelectorAll(".pgi-title a").forEach(anchor => {
        const parent = anchor.parentNode;
        while (anchor.firstChild) parent.insertBefore(anchor.firstChild, anchor);
        parent.removeChild(anchor);
        });
    }
    
    // Play video on hover
    // ====================
    document.querySelectorAll(".pgi-image-wrap").forEach(wrapper => {
        wrapper.addEventListener("mouseenter", () => {
        wrapper.querySelectorAll("video").forEach(video => video.play());
        });
        wrapper.addEventListener("mouseleave", () => {
        wrapper.querySelectorAll("video").forEach(video => video.pause());
        });
    });
    
    // Portfolio grid categories filter
    // =================================
    const ttgrCatNav = document.querySelector(".ttgr-cat-nav");
    if (ttgrCatNav) {
        ttgrCatNav.appendTo(document.getElementById("body-inner"));
    }
    
    // On category trigger click.
    const ttgrCatTrigger = document.querySelector(".ttgr-cat-trigger");
    if (ttgrCatTrigger) {
        ttgrCatTrigger.addEventListener("click", () => {
        document.body.classList.add("ttgr-cat-nav-open");
        if (document.body.classList.contains("ttgr-cat-nav-open")) {
    
        gsap.to(".portfolio-grid-item", { duration: 0.3, scale: 0.9 });
        gsap.to(".pgi-caption, #page-header, .ttgr-cat-trigger", { duration: 0.3, autoAlpha: 0 });
    
        // Make "ttgr-cat-nav" unclickable.
        document.querySelector(".ttgr-cat-nav").removeEventListener("click", ttCatNavClose);
    
        // Categories step in animations.
        const tl_ttgrIn = gsap.timeline({
            // Wait until the timeline is completed then make "ttgr-cat-nav" clickable again.
            onComplete: ttCatNavClose
        });
        tl_ttgrIn.to(".ttgr-cat-nav", { duration: 0.3, autoAlpha: 1 });
        tl_ttgrIn.from(".ttgr-cat-list > li", { duration: 0.3, y: 80, autoAlpha: 0, stagger: 0.05, ease: Power2.easeOut, clearProps: "all" });
    
        // On category link click
        document.querySelectorAll(".ttgr-cat-nav a")
            .forEach(link => {
            if (!link.target && !link.href.startsWith("#") && !link.href.startsWith("mailto") && !link.href.startsWith("tel")) {
                link.addEventListener('click', () => {
                gsap.to(".ttgr-cat-list > li", { duration: 0.3, y: -80, autoAlpha: 0, stagger: 0.05, ease: Power2.easeIn });
                });
            }
            });
        }
    });
}
    
    // On close click function
    function ttCatNavClose() {
        document.querySelector(".ttgr-cat-nav").addEventListener("click", () => {
        document.body.classList.remove("ttgr-cat-nav-open");
    
        // Categories step out animations
        const tl_ttgrClose = gsap.timeline();
        tl_ttgrClose.to(".ttgr-cat-list > li", { duration: 0.3, y: -80, autoAlpha: 0, stagger: 0.05, ease: Power2.easeIn });
        tl_ttgrClose.to(".ttgr-cat-nav", { duration: 0.3, autoAlpha: 0, clearProps: "all" }, "+=0.2");
        tl_ttgrClose.to(".portfolio-grid-item", { duration: 0.3, scale: 1, clearProps: "all" }, "-=0.4");
        tl_ttgrClose.to(".pgi-caption, #page-header, .ttgr-cat-trigger", { duration: 0.3, autoAlpha: 1, clearProps: "all" }, "-=0.4");
        tl_ttgrClose.to(".ttgr-cat-list > li", { clearProps: "all" }); // clearProps only
        });
    }




// ================================================================
// tt-Gallery
// ================================================================

	// Play video on hover
	document.querySelectorAll(".tt-gallery-video-wrap").forEach(function (wrap) {
		wrap.addEventListener("mouseenter", playVideo);
		wrap.addEventListener("touchstart", playVideo);
		wrap.addEventListener("mouseleave", pauseVideo);
		wrap.addEventListener("touchend", pauseVideo);
	});

	function playVideo() {
		this.querySelectorAll("video").forEach(function (video) {
			video.play();
		});

	}

	function pauseVideo() {
		this.querySelectorAll("video").forEach(function (video) {
			video.pause();
		});

	}

	// Gallery item image zoom on hover
	document.querySelectorAll(".tt-gallery-item").forEach(function (item) {
		if (document.querySelector(".tt-gallery").classList.contains("ttga-hover")) {

			const img = item.querySelector(".tt-gallery-image img");
			if (img) {
				const wrapper = document.createElement('div');

				wrapper.classList.add("tt-gallery-image-hover-zoom");
				img.parentNode.insertBefore(wrapper, img);
				wrapper.appendChild(img);

			}

		}
	});




    document.querySelectorAll(".tt-accordion").forEach(function (accordion) {
        accordion.querySelectorAll(".tt-accordion-item").forEach(function (item) {
            if (item.classList.contains("active")) {
                item.classList.add("active");
                gsap.set(item.querySelector(".tt-accordion-content"), { height: "auto", opacity: 1, display: "block" });
            }
        });
    
        accordion.querySelectorAll(".tt-accordion-heading").forEach(function (heading) {
            heading.addEventListener("click", function () {
                var item = this.closest(".tt-accordion-item");
                var content = item.querySelector(".tt-accordion-content");
    
                // Delay the animation slightly to ensure the scroll completes
                setTimeout(() => {
                    if (item.classList.contains("active")) {
                        gsap.to(content, {
                            duration: 0.7,
                            height: 0,
                            opacity: 0,
                            ease: "circ.out",
                            onComplete: () => {
                                item.classList.remove("active");
                                content.style.display = "none"; // Hide after animation
                            }
                        });
                    } else {
                        accordion.querySelectorAll(".tt-accordion-item.active").forEach(function (otherItem) {
                            if (otherItem !== item) {
                                gsap.to(otherItem.querySelector(".tt-accordion-content"), {
                                    duration: 0.7,
                                    height: 0,
                                    opacity: 0,
                                    ease: "circ.out",
                                    onComplete: () => {
                                        otherItem.classList.remove("active");
                                        otherItem.querySelector(".tt-accordion-content").style.display = "none"; // Hide after animation
                                    }
                                });
                            }
                        });
    
                        item.classList.add("active");
                        gsap.set(content, { height: "auto", opacity: 1, display: "block" });
                        gsap.from(content, {
                            duration: 1,
                            height: 0,
                            opacity: 0,
                            ease: "back.out"
                        });
                    }
                }, 100); // Adjust the timeout as needed
                return false;
            });
        });
    });


// ==================================
// Sidebar (classic)
// ==================================

	// If tt-sidebar exist.
	if (document.querySelector(".tt-sidebar")) {
		document.body.classList.add("tt-sidebar-on");
		// If tt-sidebar has class "sidebar-left" add class "sidebar-left-on" to <body>.
		if (document.querySelector(".tt-sidebar").classList.contains("sidebar-left")) {
			document.body.classList.add("tt-sidebar-left-on");
		}

		// If tt-sidebar has class "sidebar-right" add class "sidebar-right-on" to <body>.
		if (document.querySelector(".tt-sidebar").classList.contains("sidebar-right")) {
			document.body.classList.add("tt-sidebar-right-on");
		}
	}




// ==================================
// Scrolling text
// ==================================

    // Hover scrolling speed.
    document.querySelectorAll(".tt-scrolling-text").forEach(function (element) {
        var scrollSpeed = element.getAttribute("data-scroll-speed");
        element.querySelector(".tt-scrolling-text-inner").style.animationDuration = scrollSpeed + "s";
    });


// ================================================================
// Scroll between anchors 
// Requires "Smooth Scrollbar" (https://github.com/idiotWu/smooth-scrollbar/blob/develop/docs/api.md#scrollbarscrollintoview)
// ================================================================

document.querySelectorAll('a[href^="#"]:not([href$="#"]):not([href$="#0"])').forEach(anchor => {
    anchor.addEventListener("click", function (e) {
        e.preventDefault();

        const target = document.querySelector(this.hash);
        if (!target) return;

        let offset = 0;

        // If fixed header position enabled.
        if (document.getElementById("tt-header").classList.contains("tt-header-fixed")) {
            offset = document.getElementById("tt-header").offsetHeight;
        }

        // Use data attribute to set top offset if needed.
        if (this.dataset.offset !== undefined) {
            offset = parseInt(this.dataset.offset, 10);
        }

        const topY = target.getBoundingClientRect().top + window.pageYOffset - offset;

        window.scrollTo({ top: topY, behavior: 'smooth' });
    });
});



// ================================================================
// Scroll to top 
// ================================================================

document.querySelectorAll(".scroll-to-top").forEach(button => {
    button.addEventListener("click", function (e) {
        e.preventDefault();
        window.scrollTo({ top: 0, behavior: 'smooth' });
    });
});


// =======================================================================================
// Defer videos (Youtube, Vimeo)
// Note: When you have embed videos in your webpages it causes your page to load slower.
// Deffering will allow your page to load quickly.
// Source: https://www.feedthebot.com/pagespeed/defer-videos.html
// =======================================================================================

function initDeferVideos() {
    const vidDefer = document.getElementsByTagName("iframe");
    Array.from(vidDefer).forEach(iframe => {
        if (iframe.getAttribute("data-src")) {
            iframe.setAttribute("src", iframe.getAttribute("data-src"));
        }
    });
}

window.addEventListener('load', initDeferVideos);


// ================================================================
// Forms
// ================================================================

// Remove input placeholder on focus
document.querySelectorAll('input, textarea').forEach(element => {
    element.addEventListener('focus', function() {
        this.dataset.placeholder = this.getAttribute('placeholder');
        this.setAttribute('placeholder', '');
    });

    element.addEventListener('blur', function() {
        this.setAttribute('placeholder', this.dataset.placeholder);
    });
});

// Form "Browse File" button info
document.addEventListener('change', function(event) {
    if (event.target.matches(':file')) {
        const input = event.target;
        const numFiles = input.files ? input.files.length : 1;
        const label = input.value.replace(/\\/g, '/').replace(/.*\//, '');
        
        const customEvent = new CustomEvent('fileselect', {
            detail: { numFiles, label }
        });
        input.dispatchEvent(customEvent);
    }
});

document.querySelectorAll('input[type="file"]').forEach(fileInput => {
    fileInput.addEventListener('fileselect', function(event) {
        const { numFiles, label } = event.detail;
        const input = this.closest('.tt-form-file').querySelector('.tt-file-info');
        const log = numFiles > 1 ? numFiles + ' files selected' : label;

        if (input) {
            input.value = log;
        } else {
            if (log) alert(log);
        }
    });
});




// =======================================================================================
// Magic cursor (no effect on small screens!)
// https://codepen.io/Sahil89/pen/MQbdNR
// https://greensock.com/forums/topic/17490-follow-button-effect/?tab=comments#comment-81107
// =======================================================================================

if (!document.body.classList.contains("is-mobile") && document.body.classList.contains("tt-magic-cursor")) {
    if (window.innerWidth > 1024) {
        const magneticItems = document.querySelectorAll(".magnetic-item");
        magneticItems.forEach(item => {
            const wrap = document.createElement('div');
            wrap.classList.add("magnetic-wrap");
            item.parentNode.insertBefore(wrap, item);
            wrap.appendChild(item);
        });

        const magneticLinks = document.querySelectorAll("a.magnetic-item");
        magneticLinks.forEach(link => link.classList.add("not-hide-cursor"));

        const mouse = { x: 0, y: 0 }; // Cursor position
        const pos = { x: 0, y: 0 }; // Cursor position
        const ratio = 0.15; // delay follow cursor
        let active = false;
        const ball = document.getElementById("ball");

        const ballWidth = 36; // Ball default width
        const ballHeight = 36; // Ball default height
        const ballOpacity = 0.5; // Ball default opacity
        const ballBorderWidth = 2; // Ball default border width

        gsap.set(ball, {  // scale from middle and style ball
            xPercent: -50,
            yPercent: -50,
            width: ballWidth,
            height: ballHeight,
            borderWidth: ballBorderWidth,
            opacity: ballOpacity
        });

        document.addEventListener("mousemove", mouseMove);

        function mouseMove(e) {
            mouse.x = e.clientX;
            mouse.y = e.clientY;
        }

        gsap.ticker.add(updatePosition);

        function updatePosition() {
            if (!active) {
                pos.x += (mouse.x - pos.x) * ratio;
                pos.y += (mouse.y - pos.y) * ratio;

                gsap.set(ball, { x: pos.x, y: pos.y });
            }
        }

        document.querySelectorAll(".magnetic-wrap").forEach(wrap => {
            wrap.addEventListener("mousemove", e => {
                parallaxCursor(e, wrap, 2); // magnetic ball = low number is more attractive
                callParallax(e, wrap);
            });
        });

        function callParallax(e, parent) {
            parallaxIt(e, parent, parent.querySelector(".magnetic-item"), 25); // magnetic area = higher number is more attractive
        }

        function parallaxIt(e, parent, target, movement) {
            const boundingRect = parent.getBoundingClientRect();
            const relX = e.clientX - boundingRect.left;
            const relY = e.clientY - boundingRect.top;

            gsap.to(target, {
                duration: 0.3,
                x: ((relX - boundingRect.width / 2) / boundingRect.width) * movement,
                y: ((relY - boundingRect.height / 2) / boundingRect.height) * movement,
                ease: Power2.easeOut
            });
        }

        function parallaxCursor(e, parent, movement) {
            const rect = parent.getBoundingClientRect();
            const relX = e.clientX - rect.left;
            const relY = e.clientY - rect.top;
            pos.x = rect.left + rect.width / 2 + (relX - rect.width / 2) / movement;
            pos.y = rect.top + rect.height / 2 + (relY - rect.height / 2) / movement;
            gsap.to(ball, { duration: 0.3, x: pos.x, y: pos.y });
        }

        // Magic cursor behavior
        // ======================

        // Magnetic item hover.
        document.querySelectorAll(".magnetic-wrap").forEach(wrap => {
            wrap.addEventListener("mouseenter", () => {
                ball.classList.add("magnetic-active");
                gsap.to(ball, { duration: 0.3, width: 70, height: 70, opacity: 1 });
                active = true;
            });
            wrap.addEventListener("mouseleave", () => {
                ball.classList.remove("magnetic-active");
                gsap.to(ball, { duration: 0.3, width: ballWidth, height: ballHeight, opacity: ballOpacity });
                gsap.to(wrap.querySelector(".magnetic-item"), { duration: 0.3, x: 0, y: 0, clearProps: "all" });
                active = false;
            });
        });

        // Alternative cursor style on hover.
        const alternativeCursorElements = document.querySelectorAll(".cursor-alter, .tt-main-menu-list > li > a, .tt-main-menu-list > li > .tt-submenu-trigger > a");
        alternativeCursorElements.forEach(element => {
            if (!element.classList.contains("magnetic-item")) {
                element.addEventListener("mouseenter", () => {
                    gsap.to(ball, {
                        duration: 0.3,
                        borderWidth: 0,
                        opacity: 0.2,
                        backgroundColor: "#CCC",
                        width: "100px",
                        height: "100px",
                    });
                });
                element.addEventListener("mouseleave", () => {
                    gsap.to(ball, {
                        duration: 0.3,
                        borderWidth: ballBorderWidth,
                        opacity: ballOpacity,
                        backgroundColor: "transparent",
                        width: ballWidth,
                        height: ballHeight,
                        clearProps: "backgroundColor"
                    });
                });
            }
        });

        // Overlay menu caret hover.
        document.querySelectorAll(".tt-ol-submenu-caret-wrap .magnetic-wrap").forEach(wrap => {
            wrap.addEventListener("mouseenter", () => {
                gsap.to(ball, { duration: 0.3, scale: 0.6, borderWidth: 3 });
            });
            wrap.addEventListener("mouseleave", () => {
                gsap.to(ball, { duration: 0.3, scale: 1, borderWidth: ballBorderWidth });
            });
        });

        // Cursor view on hover (data attribute "data-cursor="...").
        document.querySelectorAll("[data-cursor]").forEach(element => {
            element.addEventListener("mouseenter", () => {
                ball.classList.add("ball-view");
                const ballViewInner = document.createElement("div");
                ballViewInner.classList.add("ball-view-inner");
                ballViewInner.innerHTML = element.getAttribute("data-cursor");
                ball.appendChild(ballViewInner);
                gsap.to(ball, { duration: 0.3, yPercent: -75, width: 95, height: 95, opacity: 1, borderWidth: 0 });
                gsap.to(".ball-view-inner", { duration: 0.3, scale: 1, autoAlpha: 1 });
            });
            element.addEventListener("mouseleave", () => {
                gsap.to(ball, { duration: 0.3, yPercent: -50, width: ballWidth, height: ballHeight, opacity: ballOpacity, borderWidth: ballBorderWidth });
                ball.classList.remove("ball-view");
                ball.querySelector(".ball-view-inner").remove();
            });
            element.classList.add("not-hide-cursor");
        });

        // Cursor close on hover.
        document.querySelectorAll(".cursor-close").forEach(element => {
            element.classList.add("ball-close-enabled");
            element.addEventListener("mouseenter", () => {
                ball.classList.add("ball-close-enabled");
                const ballClose = document.createElement("div");
                ballClose.classList.add("ball-close");
                ballClose.innerHTML = "Close";
                ball.appendChild(ballClose);
                gsap.to(ball, { duration: 0.3, yPercent: -75, width: 80, height: 80, opacity: 1 });
                gsap.from(".ball-close", { duration: 0.3, scale: 0, autoAlpha: 0 });
            });
            element.addEventListener("mouseleave", () => {
                ball.classList.remove("ball-close-enabled");
                gsap.to(ball, { duration: 0.3, yPercent: -50, width: ballWidth, height: ballHeight, opacity: ballOpacity });
                ball.querySelector(".ball-close").remove();
            });

            // Hover on "cursor-close" inner elements.
            element.querySelectorAll("a, button, .tt-btn, .hide-cursor").forEach(innerElement => {
                if (!innerElement.classList.contains("not-hide-cursor")) {
                    innerElement.addEventListener("mouseenter", () => {
                        ball.classList.remove("ball-close-enabled");
                    });
                    innerElement.addEventListener("mouseleave", () => {
                        ball.classList.add("ball-close-enabled");
                    });
                }
            });
        });

        // Blog interactive title link hover.
        document.querySelectorAll(".blog-interactive-item").forEach(item => {
            const image = item.querySelector(".bi-item-image");
            if (image) {
                const link = item.querySelector(".bi-item-title a");
                link.addEventListener("mouseenter", () => {
                    document.getElementById("magic-cursor").classList.add("blog-interactive-hover-on");
                    ball.appendChild(image.cloneNode(true));
                    gsap.to(ball, { duration: 0.3, width: "20vw", height: "20vw", opacity: 1 });
                });
                link.addEventListener("mouseleave", () => {
                    document.getElementById("magic-cursor").classList.remove("blog-interactive-hover-on");
                    ball.querySelector(".bi-item-image").remove();
                    gsap.to(ball, { duration: 0.3, width: ballWidth, height: ballHeight, opacity: ballOpacity });
                });
                link.classList.add("not-hide-cursor");
                item.classList.add("bi-item-image-on");
            }
        });

        // Show/hide magic cursor
        // =======================

        // Hide on hover.
        const hideOnHoverElements = document.querySelectorAll("a, button, .tt-btn, .tt-form-control, .tt-form-radio, .tt-form-check, .hide-cursor");
        hideOnHoverElements.forEach(element => {
            if (!element.classList.contains("not-hide-cursor") &&
                !element.classList.contains("cursor-alter") &&
                !element.matches(".tt-main-menu-list > li > a") &&
                !element.matches(".tt-main-menu-list > li > .tt-submenu-trigger > a")) {
                element.addEventListener("mouseenter", () => {
                    gsap.to(ball, { duration: 0.3, scale: 0, opacity: 0 });
                });
                element.addEventListener("mouseleave", () => {
                    gsap.to(ball, { duration: 0.3, scale: 1, opacity: ballOpacity });
                });
            }
        });

        // Hide on click.
        document.querySelectorAll("a").forEach(link => {
            if (!link.target &&
                !link.href.startsWith("#") &&
                !link.href.startsWith("mailto") &&
                !link.href.startsWith("tel") &&
                !link.classList.contains("lg-trigger") &&
                !link.classList.contains("tt-btn-disabled")) {
                link.addEventListener("click", () => {
                    gsap.to(ball, { duration: 0.3, scale: 1.3, autoAlpha: 0 });
                });
            }
        });

        // Show/hide on document leave/enter.
        document.addEventListener("mouseleave", () => {
            gsap.to("#magic-cursor", { duration: 0.3, autoAlpha: 0 });
        });
        document.addEventListener("mouseenter", () => {
            gsap.to("#magic-cursor", { duration: 0.3, autoAlpha: 1 });
        });

        // Show as the mouse moves.
        document.addEventListener("mousemove", () => {
            gsap.to("#magic-cursor", { duration: 0.3, autoAlpha: 1 });
        });
    }
}



// ==========================================================================
// Template style switch
// NOTE: Remove this code if you do not want to use the template light style.
// ==========================================================================

// Display style switch button
const bodyInner = document.getElementById('body-inner');
const styleSwitch = document.createElement('div');
styleSwitch.className = 'tt-style-switch';
styleSwitch.innerHTML = '<div class="tt-stsw-dark"><i class="far fa-moon"></i></div><div class="tt-stsw-light"><i class="fas fa-sun"></i></div>';
bodyInner.prepend(styleSwitch);


if (document.getElementById('tt-light-style-default')) {
    // Click on style switch
    styleSwitch.addEventListener('click', () => {
        document.body.classList.toggle('tt-light-style-default-on');
        localStorage.setItem('lightstyle', document.body.classList.contains('tt-light-style-default-on'));
        location.reload();
    });

    // localStorage
    const lightstyle = localStorage.getItem('lightstyle');
    if (lightstyle === 'true') {
        document.body.classList.add('tt-light-style-default-on');
    } else {
        document.body.classList.remove('tt-light-style-default-on');
    }

    // Toggle light stylesheet
    if (document.body.classList.contains('tt-light-style-default-on')) {
        document.getElementById('tt-light-style-default').remove();
    }
} else {
    // Click on style switch
    styleSwitch.addEventListener('click', () => {
        document.body.classList.toggle('tt-light-style-on');
        localStorage.setItem('lightstyle', document.body.classList.contains('tt-light-style-on'));
        location.reload();
    });

    // localStorage
    const lightstyle = localStorage.getItem('lightstyle');
    if (lightstyle === 'true') {
        document.body.classList.add('tt-light-style-on');
        styleSwitch.classList.add('is-dark');
    } else {
        document.body.classList.remove('tt-light-style-on');
    }

    // Toggle light stylesheet
    if (document.body.classList.contains('tt-light-style-on')) {
        const lightStyle = document.createElement('link');
        lightStyle.id = 'tt-light-style';
        lightStyle.rel = 'stylesheet';
        lightStyle.href = '/assets/css/light-style.css';
        document.getElementById('tt-themecss').after(lightStyle);
    } else {
        const lightStyle = document.getElementById('tt-light-style');
        if (lightStyle) lightStyle.remove();
    }
}

// End template style switch



// ================================================================
// Miscellaneous
// ================================================================

// tt-Button disabled (prevent click)
// ===================
document.querySelectorAll('.tt-btn-disabled').forEach(button => {
    button.addEventListener('click', (e) => {
        e.preventDefault();
        return false;
    });
});

// Force page scroll position to top on refresh (do not remove!)
// =============================================
window.addEventListener('pagehide', () => {
    window.scrollTo(0, 0);
});

// Hover fix for iOS
// ==================
document.addEventListener('touchstart', (e) => {
    if (e.target instanceof Element) {
        const hoverEvent = new Event('hover');
        e.target.dispatchEvent(hoverEvent);
    }
});

document.addEventListener('touchend', (e) => {
    if (e.target instanceof Element) {
        const hoverEvent = new Event('hover');
        e.target.dispatchEvent(hoverEvent);
    }
});


// Typewriter effect - homepage
// ==================
if (window.location.pathname == "/") {
	ityped.init(document.querySelector("#services-appear"), {
		strings: ['e-commerce solutions', 'theme development', 'app development']
	})
}